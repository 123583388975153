@import "src/assets/styles/vars";

.modal {
  height: unset;

  h2 {
    font-weight: 600;
    font-size: 20px;
    position: relative;
    padding-bottom: 8px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: #CFCFCF;
    }
  }

  .content {
    font-size: 16px;
    margin-top: 15px;
  }
}
