@import "src/assets/styles/vars";

.purchaseModal {
  width: 700px;

  @media (max-width: 720px) {
    width: calc(100vw - 20px);
  }

  h1 {
    text-align: center;
    font-size: 30px;

    @media (max-width: 400px) {
      font-size: 28px;
    }
  }

  h2 {
    margin-top: 10px;
    font-size: 22px;
    @media (max-width: 400px) {
      font-size: 20px;
    }
  }

  h3 {
    margin-top: 15px;
    font-size: 16px;
    @media (max-width: 400px) {
      font-size: 14px;
    }

    span {
      color: $base-color;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: unset;
      }
    }
  }

  h4 {
    margin-top: 15px;

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }

  .btnWrapper {
    margin-top: 20px;
    padding: 0 !important;
    justify-content: center !important;
  }
}