@import "src/assets/styles/vars";

.modal {
  width: 570px;
  font-size: 16px;
  position: relative;

  @media (max-width: 650px) {
    width: calc(100vw - 100px)
  }
  @media (max-width: 470px) {
    margin: 10px 0;
    width: calc(100vw - 70px);
    font-size: 14px;
    overflow-y: auto;
    max-height: calc(100vh - 90px);
  }

  &TabList {
    display: flex;
  }

  &Tab {
    font-family: "Montserrat", sans-serif;
    padding: 0 25px;
    line-height: 48px;
    border-top: 1px solid #ebebeb;
    cursor: pointer;
    user-select: none;
    font-weight: bold;

    @media (max-width: 470px) {
      width: 50%;
      padding: 0;
      text-align: center;
    }

    &.selected {
      position: relative;
      cursor: default;

      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #fff;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }

    &:first-child {
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      border-radius: 5px 0 0;
    }

    &:last-child {
      border-right: 1px solid #ebebeb;
      border-radius: 0 5px 0 0;
    }
  }

  &TabPanel {
    border-radius: 0 5px 5px 5px;
    border: 1px solid #ebebeb;
    padding: 47px 37px 30px;
    display: none;
    @media (max-width: 540px) {
      padding: 23px 18px 15px;
    }
    @media (max-width: 470px) {
      border-radius: 0 0 5px 5px;
      padding: 10px;
    }

    &.selected {
      display: block;
    }

    form {
      .req {
        width: max-content;
        margin: 20px auto 0 auto;
      }

      .forgotPassword {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          list-style: none;
          cursor: pointer;
          background-color: transparent;
          font-size: 16px;
          color: $base-color;
          transition: .1s ease-in-out;
          border-bottom: 1px solid transparent;


          &:hover {
            color: darken($base-color, 20%);
            border-bottom-color: darken($base-color, 20%);
          }
        }
      }

      .formControl {
        display: grid;
        grid-template: "label input"
                         ". invalid" / 20% 80%;
        align-items: center;
        margin-top: 20px;

        &.captcha {
          grid-template: "block" "invalid";

          span {
            text-align: center;
          }
        }

        @media (max-width: 470px) {
          grid-template: "label ."
                         "input input"
                         "invalid invalid"/20% 80%;
        }

        //&.checkBox {
        //  display: inline-block;
        //  margin-top: 5px;
        //  margin-left: calc(20% + 6px);
        //  user-select: none;
        //  label {
        //    display: flex;
        //    align-items: center;
        //    cursor: pointer;
        //  }
        //  input[type='checkbox']{
        //    width: 0;
        //    height: 0;
        //    opacity: 0;
        //  }
        //}

        &:first-child {
          margin-top: 0;
        }

        span {
          grid-area: invalid;
          color: $error-color;
          margin-top: 5px;
        }

        label {
          font-size: 16px;
          text-align: right;
          margin-right: 10px;
          grid-area: label;
          @media (max-width: 470px) {
            margin: 0 0 5px 0;
            text-align: left;
            font-size: 14px;
          }
        }

        input {
          grid-area: input;
          border: 1px solid #acacac;
          background: #fff;
          border-radius: 4px;
          line-height: normal;
          vertical-align: middle;
          transition: .1s ease-in-out;
          padding: 6px 8px;
          font-size: 16px;
          height: 36px;
          @media (max-width: 470px) {
            font-size: 14px;
          }

          &.invalid {
            border-color: $error-color;
          }

          &:focus {
            border-color: #0E0D0A;
          }
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        @media (max-width: 470px) {
          align-items: center;
        }

        button {
          padding: 10px 38px;
          color: #FFFFFF;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1px;
          background: $base-color;
          border: 2px solid $base-color;
          border-radius: 4px;
          overflow: hidden;
          cursor: pointer;
          transition: .1s ease-in-out;
          @media (max-width: 470px) {
            width: 100%;
            padding: 10px 19px;
            font-size: 14px;
          }

          @media (max-width: 350px) {
            padding: 10px 5px;
          }

          &:hover {
            background-color: $base-color-darken;
            border-color: $base-color-darken
          }
        }
      }
    }


  }
}
