@import "src/assets/styles/vars";

.shipmentForm {
  .inputBlock {
    .invalid {
      border-color: $error-color !important;
      margin-bottom: 5px;
    }

    span.error {
      color: $error-color;
      margin-left: 17px;
    }

    input {
      border: 1px solid #757575;
      height: 56px;
      line-height: 24px;
      padding: 18px 16px;
      font-size: 16px;
      letter-spacing: .5px;
      color: #333;
      border-radius: 4px;
      width: 100%;
      transition: .1s ease-in-out;

      &:focus {
        border-color: $base-color !important;
      }
    }

    label {
      margin-bottom: 15px;
      font-size: 16px;
      display: block;


    }
  }

  sup {
    color: $error-color;
    margin-left: 5px;
  }

  .flex {
    display: flex;
    align-items: center;

    &.invalid .formBlock input {
      margin-bottom: 20px;
    }


    & .formBlock {
      width: 100%;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .formBlock {
    font-size: 16px;
    margin-bottom: 10px;
    position: relative;

    &.w100 {
      max-width: 100%;
    }

    label {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .input {
      border: 1px solid transparent;
      box-shadow: 0 0 4px rgb(0 0 0 / 20%);
      height: 56px;
      line-height: 24px;
      padding: 18px 16px;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: #333;
      border-radius: 4px;
      width: 100%;
      transition: border-color 0.1s ease-in-out;

      &.invalid {
        border-color: $error-color;
        margin-bottom: 20px;
      }

      &:focus {
        border-color: $base-color;
      }
    }

    .textarea {
      height: 150px;
      resize: none;
    }

    span.error {
      color: $error-color;
      position: absolute;
      bottom: 0;
      left: 17px;
    }
  }

  .block {
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
    font-size: 16px;
    border-radius: 5px;

    &.isCheckout {
      padding: 30px 30px 32px;
      background-color: unset;
      margin-bottom: 0;
      height: 100%;

      @media (max-width: 500px) {
        padding: 15px;
      }

      .edit {
        //grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
        //grid-auto-rows: minmax(0, 1fr);
        //height: 100%;
      }
    }

    .select {
      background-color: #fff;
      border: 1px solid transparent;
      max-width: unset;

      &.invalid {
        margin-bottom: 10px;

        & > div:first-child {
          border: 1px solid $error-color;
        }

        & ~ span {
          color: $error-color;
        }
      }

      &Region {
        overflow-y: scroll;
      }
    }

    h2 {
      font-size: 20px;
      padding: 12px 0;
      margin-bottom: 6px;
    }
  }

  .blur {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, .8);;
    }
  }

  .edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    grid-template-areas: "region city" "shipment shipment" "address address" ". btns";

    @media (max-width: 720px) {
      grid-template-areas: "region region" "city city" "shipment shipment" "address address" ". btns";
      gap: 15px;
    }

    @media (max-width: 500px) {
      grid-template-areas: "region region" "city city" "shipment shipment" "address address" "btns btns";
    }

    &.isAdditional {
      grid-template-areas: "name name" "region city" "shipment shipment" "address address" ". btns";

      @media (max-width: 720px) {
        grid-template-areas: "name name" "region region" "city city" "shipment shipment" "address address" ". btns";
      }

      .name {
        grid-area: name;
        display: flex;
        flex-wrap: wrap;

        & > div {
          flex: 1 1 0;
          min-width: 45%;
          @media (max-width: 720px) {
            width: 100%;
            margin: 0 0 20px !important;
            min-width: unset;
            flex: unset;
            &:last-child {
              margin-bottom: 0 !important;
            }
          }

          &:first-child {
            margin-right: 30px;
            margin-bottom: 30px;
          }

          &:last-child {
            margin-left: 30px;
          }
        }
      }
    }

    .region {
      grid-area: region;
    }

    .city {
      grid-area: city;
    }

    .address {
      grid-area: address;
    }

    .shipment {
      grid-area: shipment;
    }

    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-area: btns;

      @media (max-width: 420px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        button {
          width: 100%;
          margin-right: 0 !important;

          &:last-child {
            margin-top: 20px;
          }
        }
      }

      &.isCheckout {
        grid-row: span 1;

        button:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .radio {
    padding: 6px;
    border-radius: 50px;
    display: inline-flex;
    cursor: pointer;
    transition: background .1s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:focus-within {
      background: rgb(0 0 0 / 10%);
    }

    input {
      vertical-align: middle;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: none;
      border: 0;
      box-shadow: inset 0 0 0 1.5px rgb(0 0 0 / 20%);
      appearance: none;
      padding: 0;
      margin: 0;
      transition: box-shadow .1s cubic-bezier(.95, .15, .5, 1.25);
      pointer-events: none;

      &:focus {
        outline: none;
      }

      &:checked {
        box-shadow: inset 0 0 0 6px $base-color;
      }
    }

    span {
      vertical-align: middle;
      display: inline-block;
      line-height: 20px;
      padding: 0 8px;
    }

    &Error {
      color: $error-color;

    }
  }
}
