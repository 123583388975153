@import "src/assets/styles/vars";

.select {
  position: relative;
  display: block;
  width: 45px;
  max-width: 400px;
  user-select: none;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 0 7px;

  &:first-child {
    border-right: 0;
  }

  &:last-child {
    border-left: 0;
  }

  &edHead {
    width: 100%;
    text-align: center;
    color: #000;
    cursor: pointer;

    &.open {
      &:after {
        transform: rotate(180deg) translateY(-50%);
      }
    }

    &:after {
      width: 6px;
      height: 4px;
      background: transparent url("../../../assets/svg/angle.svg") no-repeat center/cover;
      position: absolute;
      right: 3px;
      bottom: 50%;
      transform: translateY(50%);
      content: '';
      display: block;
      transition: .2s ease-in-out;

    }
  }

  &List {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    width: calc(100% + 2px);
    right: 0;
    background: #fff;
    z-index: 100;
    color: #424348;
    overflow-y: hidden;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 1px 1px 1px rgba(0, 0, 0, 0.25);
  }

  &Item {
    position: relative;
    border-top: 1px solid #D0D0D0;
    padding: 5px 13px;
    cursor: pointer;
    list-style-type: none;
    transition: .1s ease-in-out;
    text-align: center;

    &:first-child {
      border-top: 0;
    }

    &:hover {
      color: $base-color;
      background-color: #e6e6e6;
    }
  }
}
