@import "src/assets/styles/vars";

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  width: calc(100vw - 60px);
  background-color: #E0E0E0;

  @media (max-width: 900px) {
    justify-content: flex-end;
  }

  @media (max-width: 550px) {
    padding: 25px 0;
    justify-content: center;
  }

  .greeting {
    font-size: 16px;

    @media (max-width: 900px) {
      display: none;
    }

    span {
      font-weight: 600;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;

    &ActionBtn {
      border-radius: 10px;
      padding: 7px 11px;
      margin-left: 20px;
    }

    .logout {
      cursor: pointer;
      width: 30px;
      height: 30px;

      :global {
        .v, .x {
          transition: .1s ease-in-out;
        }
      }

      &:hover {
        :global {
          .v, .x {
            fill: $base-color;
          }
        }
      }
    }

    &Btn {
      display: block;
      //display: inline-block;
      //font-size: 16px;
      //line-height: 20px;
      //background: none;
      //border-bottom: 1px dashed #000;
      margin: 0 25px;
      transition: .1s ease-in-out;

      svg {
        width: 28px;
        height: 28px;
      }

      &:hover {
        //border-bottom-color: transparent;
        //color: $base-color;
      }
    }
  }
}
